@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'DMSans';
    font-style: normal;
    font-weight: 400;
    src: url('../Assets/fonts/DMSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'DMSans';
    font-style: normal;
    font-weight: 500;
    src: url('../Assets/fonts/DMSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'DMSans';
    font-style: normal;
    font-weight: 700;
    src: url('../Assets/fonts/DMSans-Bold.ttf') format('truetype');
}

body {
    margin: 0;
    background: #edeef3;
    font-family: 'DMSans', sans-serif;
    box-sizing: border-box;
    min-height: 100vh;
    letter-spacing: -0.29px;
}

section {
    padding: 6em 2em 1.5em 2em;
}
.w-inherit {
    width: inherit;
    max-width: inherit;
}
/*                    EXPIRED FLAG                   */

.expired-insurance {
    border: 1px solid #d45e00;
    border-radius: 5px;
    position: relative;
}

.expired-insurance::after {
    background: #ff7100;
    color: #fff;
    content: 'VENCIDO';
    border-radius: 2px;
    position: absolute;
    font-family: 'DMSans', sans-serif;
    top: -5px;
    right: -10px;
    padding: 1px 15px;
    font-size: 11px;
}
.expired-insurance::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 9px solid #9b4602;
    border-right: 9px solid transparent;
    position: absolute;
    top: 13px;
    right: -9px;
}

::placeholder {
    font-size: 14px;
}

/*                    ALERT MODAL ANIMATION                   */

@keyframes modalSlide {
    from {
        transform: translate(-200px, -140px);
        opacity: 0;
    }
    to {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}

.card-transition {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
}
