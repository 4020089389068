.customized-form button {
    background: #c00200;
    border: 1px solid #c00200;
    border-radius: 5px;
    width: 100%;
    height: 3.5em;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    font-family: 'DMSans', sans-serif;
}

.customized-form button:disabled {
    background: #e0a7a6;
    border: 1px solid #e0a7a6;
}

.customized-form input::placeholder {
    color: #2d373d;
    font-size: 14px;
    font-weight: 300;
}

.customized-form input:not([type='checkbox']):not([type='radio']) {
    background: #fff;
    width: 100%;
    border: 1.5px solid #afafaf;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 6px;
    padding: 10px;
}

.customized-form label {
    font-size: 14px;
    color: #2d373d;
    font-family: 'DMSans', sans-serif;
}

.disabled-input {
    margin-right: 0.9px;
}
