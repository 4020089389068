h1 {
  font-size: 42px;
  line-height: 50px;
  letter-spacing: -0.29px;
}

h2 {
  font-size: 34px;
  line-height: 50px;
  letter-spacing: -0.29px;
}

h3 {
  font-size: 28px;
  line-height: normal;
  letter-spacing: -0.29px;
}

h4 {
  font-size: 24px;
  line-height: normal;
  letter-spacing: -0.29px;
}

h5 {
  font-size: 20px;
  letter-spacing: -0.29px;
}

p {
  font-size: 14px;
}

small {
  font-size: 12px;
}
